import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from './icon';
import { Flex } from './styles/flex';
import { borderLightGray, gray, lightGray } from './styles/variables';

/**
 * This component renders a set of tabs, and a content which is determine
 * by the activeTab value.
 *
 * Props:
 *   tabs: An array of tab objects, each with a name, label, icon, and component.
 *   initialTab: The initial active tab name.
 *   tabProps: Additional props passed to the active tab's component.
 *   onTabChange: A callback function to be called when the active tab changes.
 *                with active tab name as a param.
 *   searchParamName: The name of the URL search parameter to store the active tab.
 *   bodyStyle: a style object to be applied to the wrapper around rendered component
 *   buttonsStyle: a style object to be applied to the wrapper around tab buttons
 */
export const Tabs = ({
  bodyStyle,
  buttonsStyle,
  initialTab,
  onTabChange,
  searchParamName,
  tabProps,
  tabs,
}) => {
  const [activeTab, setLocaleActiveTab] = useState();

  // on first render
  useEffect(() => {
    // if there is no activeTab prop
    if (!initialTab) {
      // check if there is a url search param,
      const urlParams = new URLSearchParams(window.location.search);
      const urlTab = urlParams.get(searchParamName);

      if (urlTab && tabs.some((tab) => tab.name === urlTab)) {
        // and set the active tab
        setLocaleActiveTab(urlTab);
      } else {
        setLocaleActiveTab(tabs[0]?.name);
      }
    } else {
      setLocaleActiveTab(initialTab);
    }
  }, []); // eslint-disable-line

  // on activeTab change
  useEffect(() => {
    // update the search param
    setActiveTab(activeTab, searchParamName);
    onTabChange && activeTab && onTabChange(activeTab);

    return () => removeSearchParam(searchParamName);
  }, [activeTab]); // eslint-disable-line

  const currentTab = tabs.find((tab) => tab.name === activeTab);

  return (
    <Flex flexDirection="column">
      <Flex {...buttonsStyle}>
        {tabs.map(({ name, label, icon }) => (
          <TabButton
            key={name}
            name={name}
            label={label}
            icon={icon}
            isActive={activeTab === name}
            onClick={setLocaleActiveTab}
          />
        ))}
      </Flex>
      <Flex
        flexGrow={1}
        marginTop={'16px'}
        flexDirection="column"
        {...bodyStyle}
      >
        {currentTab ? (
          <currentTab.component
            activeTab={activeTab || currentTab.name}
            setActiveTab={setLocaleActiveTab}
            {...tabProps}
          />
        ) : null}
      </Flex>
    </Flex>
  );
};

Tabs.defaultProps = {
  searchParamName: 'tab',
};

Tabs.propTypes = {
  bodyStyle: PropTypes.object,
  buttonsStyle: PropTypes.object,
  initialTab: PropTypes.string,
  onTabChange: PropTypes.func,
  searchParamName: PropTypes.string,
  tabProps: PropTypes.any,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      component: PropTypes.func.isRequired,
    })
  ).isRequired,
};

// set active tab in the url search params
export const setActiveTab = (activeTab, searchParamName = 'tab') => {
  const url = new URL(window.location);
  url.searchParams.set(searchParamName, activeTab);

  // we use history to avoid reloads
  window.history.pushState({}, '', url);
};

const StyledTabButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  border: none;
  background: inherit;
  color: ${gray}
    ${({ active }) =>
      active
        ? `color: ${lightGray}; box-shadow: inset 0 -2px 0 0 ${borderLightGray}; font-weight: 700;`
        : ''};
`;

/**
 * This component represents a single tab button within the Tabs component.
 *
 * Props:
 *   name: The name of the tab.
 *   icon: The name of the tab's icon.
 *   label: The label text of the tab.
 *   isActive: Indicates whether this tab is currently active.
 *   setActive: A callback function to change the active tab.
 */
export const TabButton = ({ name, icon, label, isActive, onClick }) => {
  return (
    <StyledTabButton onClick={() => onClick?.(name)} active={isActive}>
      {icon && <Icon size={20} name={icon} />}
      <span>{label}</span>
    </StyledTabButton>
  );
};

const removeSearchParam = (searchParamName) => {
  // get the current URL parameters
  const searchParams = new URLSearchParams(window.location.search);

  // remove the tabs parameter
  searchParams.delete(searchParamName);

  const newSearch = searchParams.toString();

  // create a new URL without the specified parameter
  const newUrl = `${window.location.pathname}${
    newSearch ? `?${newSearch}` : ''
  }${window.location.hash}`;

  // use the history to avoid refresh
  window.history.replaceState({}, '', newUrl);
};

TabButton.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};
